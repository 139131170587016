import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { getErrorDetails } from "./getErrorMessage.ts";

export const useErrorToast = (error: unknown, title?: string) => {
    const toast = useToast();
    useEffect(() => {
        if (!error) return;
        const errorDetails = getErrorDetails(error);
        toast({
            title: title ?? "Error",
            description: errorDetails.message,
            status: "error",
        });
    }, [error, title, toast]);
};
