import { Avatar, Box, Card, Flex, Heading, HStack, IconButton, Stack, Text, Tooltip } from "@chakra-ui/react";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { FC } from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../lib/util/formatNumber.ts";
import { locationMapping } from "../../../lib/util/locationMapping.ts";
import { SimilarCompany } from "../../../sdk/structures/SimilarCompany.ts";

interface CompanyCardProps {
    company: SimilarCompany;
}
export const CompanyCard: FC<CompanyCardProps> = ({ company }) => {
    // all combinations of latest funding date and amount
    const latestFundingStatus = () => {
        if (!company.last_funding_amount && !company.last_funding_date) {
            return null;
        }
        if (company.last_funding_amount && !company.last_funding_date) {
            return formatNumber(company.last_funding_amount);
        }
        if (!company.last_funding_amount && company.last_funding_date) {
            return DateTime.fromISO(company.last_funding_date).toFormat("yyyy");
        }
        if (company.last_funding_amount && company.last_funding_date) {
            return (
                formatNumber(company.last_funding_amount) +
                ", " +
                DateTime.fromISO(company.last_funding_date).toFormat("yyyy")
            );
        }
    };

    const companyLocationLabel = (): string | undefined => {
        if (company.country && company.continent) {
            return `${company.country}, ${
                company.continent in locationMapping ? locationMapping[company.continent] : company.continent
            }`;
        }
        if (company.country && !company.continent) {
            return `${company.country}`;
        }
        if (!company.country && company.continent) {
            return `${company.continent in locationMapping ? locationMapping[company.continent] : company.continent}`;
        }
    };

    return (
        // White card wrapping a company
        <Card variant={"flat"} key={company.id}>
            <Stack gap={4}>
                <HStack justifyContent={"space-between"} gap={6} alignItems={"start"}>
                    {/* company logo */}
                    <Avatar name={company.name} src={company.image ?? undefined} size={"lg"} borderRadius={"xl"} />

                    {/* company name and description */}
                    <Stack gap={0} flexGrow={1} minH={16} justifyContent={"start"}>
                        <Flex gap={4}>
                            {company.website_url && (
                                <Link to={company.website_url} target={"_blank"} rel={"noreferrer"}>
                                    <Heading _hover={{ textDecoration: "underline" }} variant={"h3"}>
                                        {company.name}
                                    </Heading>
                                </Link>
                            )}
                            <HStack gap={2}>
                                {/* company link website link url */}
                                {company.website_url && (
                                    <Tooltip hasArrow label="Visit website">
                                        <Link to={company.website_url} target={"_blank"} rel={"noreferrer"}>
                                            <IconButton
                                                borderRadius={"md"}
                                                aria-label={"website link"}
                                                bg={"gray.300"}
                                                variant={"solid"}
                                                size={"xs"}
                                                icon={<FontAwesomeIcon icon={faLink} />}
                                            />
                                        </Link>
                                    </Tooltip>
                                )}
                                {company.linkedin_url && (
                                    // company linkedin url
                                    <Tooltip hasArrow label="Visit LinkedIn page">
                                        <Link to={company.linkedin_url} target={"_blank"} rel={"noreferrer"}>
                                            <IconButton
                                                borderRadius={"md"}
                                                aria-label={"LinkedIn link"}
                                                variant={"solid"}
                                                colorScheme={"linkedinBlue"}
                                                size={"xs"}
                                                icon={<FontAwesomeIcon icon={faLinkedinIn} />}
                                            />
                                        </Link>
                                    </Tooltip>
                                )}
                            </HStack>
                        </Flex>
                        <Text variant={"body2"} color={companyLocationLabel() ? "gray.800" : "gray.500"}>
                            {companyLocationLabel() ?? "No location info"}
                        </Text>
                    </Stack>

                    {/* company stats */}
                    <Stack gap={1}>
                        <Text variant={"labelCaps"} color={"gray.600"}>
                            Launch year
                        </Text>
                        <Text
                            variant={company.launch_year ? "bodyBold" : "body"}
                            color={company.launch_year ? "black.500" : "gray.500"}
                        >
                            {company.launch_year ?? "No info"}
                        </Text>
                    </Stack>

                    <Stack gap={1}>
                        <Text variant={"labelCaps"} color={"gray.600"}>
                            Employees
                        </Text>
                        <Text
                            variant={company.employees_latest ? "bodyBold" : "body"}
                            color={company.employees_latest ? "black.500" : "gray.500"}
                        >
                            {company.employees_latest ? company.employees_latest.toLocaleString() : "No info"}
                        </Text>
                    </Stack>

                    <Stack gap={1}>
                        <Text variant={"labelCaps"} color={"gray.600"}>
                            Latest funding
                        </Text>
                        <Text
                            variant={company.last_funding_date ? "bodyBold" : "body"}
                            color={company.last_funding_date ? "black.500" : "gray.500"}
                        >
                            {!latestFundingStatus() && "No info"}
                            {latestFundingStatus()}
                        </Text>
                    </Stack>

                    {/* @todo: add currency so this is accurate */}
                    <Stack gap={1}>
                        <Text variant={"labelCaps"} color={"gray.600"}>
                            Total Funding
                        </Text>
                        <Text
                            variant={company.total_funding_amount ? "bodyBold" : "body"}
                            color={company.total_funding_amount ? "black.500" : "gray.500"}
                        >
                            {!company.total_funding_amount && "No info"}
                            {!!company.total_funding_amount && formatNumber(company.total_funding_amount)}
                        </Text>
                    </Stack>
                </HStack>

                {/* company description */}
                <Box bg={"gray.100"} borderRadius={"2xl"} p={4}>
                    <Text variant={"body2"} color={company.description ? "gray.800" : "gray.500"}>
                        {company.description ?? "No description provided"}
                    </Text>
                </Box>
            </Stack>
        </Card>
    );
};
