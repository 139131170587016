/**
 * @packageDocumentation
 * @module api.functional.$case.events
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from '@nestia/fetcher';
import { PlainFetcher } from '@nestia/fetcher/lib/PlainFetcher';

/**
 * @controller CaseController.getCaseEvents
 * @path GET /case/:id/events
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getCaseEvents(connection: IConnection, id: number): Promise<getCaseEvents.Output> {
    return PlainFetcher.fetch(connection, {
        ...getCaseEvents.METADATA,
        template: getCaseEvents.METADATA.path,
        path: getCaseEvents.path(id),
    });
}
export namespace getCaseEvents {
    export type Output = any;

    export const METADATA = {
        method: "GET",
        path: "/case/:id/events",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (id: number) => `/case/${encodeURIComponent(id ?? "null")}/events`;
}
