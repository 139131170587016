import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useMembersQuery = (caseId?: number) => {
    return useQuery({
        queryKey: ["members", caseId],
        queryFn: async () => {
            if (!caseId) return [];
            return await sdk.functional.$case.member.getMembers(getApiConnection(), caseId);
        },
        staleTime: 60_000, // 1 min
        retryOnMount: false,
        enabled: !!caseId,
    });
};
