/**
 * @packageDocumentation
 * @module api.functional.ai.chunk
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from '@nestia/fetcher';
import { PlainFetcher } from '@nestia/fetcher/lib/PlainFetcher';

import type { ChunkInfoDto } from '../../../structures/ChunkInfoDto';

/**
 * @controller AiController.getChunkInfo
 * @path GET /ai/chunk/:chunkId
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getChunkInfo(connection: IConnection, chunkId: string): Promise<getChunkInfo.Output> {
    return PlainFetcher.fetch(connection, {
        ...getChunkInfo.METADATA,
        template: getChunkInfo.METADATA.path,
        path: getChunkInfo.path(chunkId),
    });
}
export namespace getChunkInfo {
    export type Output = ChunkInfoDto;

    export const METADATA = {
        method: "GET",
        path: "/ai/chunk/:chunkId",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (chunkId: string) => `/ai/chunk/${encodeURIComponent(chunkId ?? "null")}`;
}
