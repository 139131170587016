import { Avatar, Box, HStack, Icon, IconButton, MenuItem, Stack, Text, Tooltip } from "@chakra-ui/react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useClearNotification } from "../../lib/mutations/useClearNotification.ts";
import { getNotifications } from "../../sdk/functional/notification";
import { NotificationBadge } from "../misc/notificationBadge/NotificationBadge.tsx";
import maeveLogo from "../../assets/logos/maeve-logo-notification.svg";

interface Props {
    notification: getNotifications.Output[0];
}
export const Notification: FC<Props> = ({ notification }) => {
    const { mutate: clearNotification } = useClearNotification(notification.id);

    const message = notification.message
        .replaceAll("{fromUser}", notification.from_user?.name ?? "Unknown user")
        .replaceAll("{caseName}", notification.case?.name ?? "Unknown case");

    let url: string | undefined;
    if (notification.case_id) url = `/cases/${notification.case_id}`;

    const time = DateTime.fromISO(notification.created_at);

    return (
        <MenuItem
            as={url ? Link : Box}
            pr={1}
            cursor={url ? "pointer" : "default"}
            // @ts-expect-error to exists on Link, but not on Box
            to={url}
        >
            <HStack gap={4} flexGrow={1} align={"start"}>
                <NotificationBadge enabled={!notification.is_read} top={1}>
                    <Avatar
                        size="sm"
                        name={notification.from_user?.name ?? "???"}
                        src={notification.from_user?.picture ?? maeveLogo}
                    />
                </NotificationBadge>

                <Stack flexGrow={1} gap={1}>
                    <Text color="white" variant="body2">
                        {message}
                    </Text>
                    <Text color="gray.500" variant="body2">
                        {time.toLocaleString(DateTime.DATETIME_MED)}
                    </Text>
                </Stack>

                <Tooltip content={"Clear notification"} placement={"top"} hasArrow>
                    <IconButton
                        aria-label="Clear notification"
                        icon={<Icon as={FontAwesomeIcon} boxSize={4} icon={faXmark} />}
                        size="sm"
                        variant="link"
                        color="gray.500"
                        top={1}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            clearNotification();
                        }}
                    >
                        Clear all
                    </IconButton>
                </Tooltip>
            </HStack>
        </MenuItem>
    );
};
