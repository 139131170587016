import "./lib/app/sentry.ts"; // As the first import
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { App } from "./App.tsx";
import { oidcConfig } from "./lib/app/oidc.ts";
import { queryClient } from "./lib/app/queryClient.ts";
import { theme } from "./lib/app/theme.ts";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={theme} resetCSS={true}>
                    <App />
                </ChakraProvider>
            </QueryClientProvider>
        </AuthProvider>
    </React.StrictMode>,
);
