import {
    Button,
    Heading,
    HStack,
    Icon,
    IconButton,
    Img,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useParams } from "react-router-dom";
import FileIcon from "../../../../assets/images/file.png";
import { UploadBox } from "../../../../components/files/uploadBox/UploadBox.tsx";
import { useUploads } from "./hooks/useUploads.ts";

interface NewAssetPopoverProps {}
export const NewAssetPopover: FC<NewAssetPopoverProps> = ({}) => {
    const { caseId } = useParams<"caseId">();

    const popoverDisclosure = useDisclosure();

    const { form, onSubmit, mergeFiles, removeFile, isUploading } = useUploads(
        parseInt(caseId ?? "-1"),
        popoverDisclosure.onClose,
    );

    return (
        <Popover placement={"bottom-end"} {...popoverDisclosure}>
            <PopoverTrigger>
                <Button colorScheme={"purple"} leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} />}>
                    New asset
                </Button>
            </PopoverTrigger>
            <PopoverContent w={"660px"} shadow={"2xl"} borderRadius={"3xl"} px={6} py={4}>
                <PopoverArrow />
                <PopoverCloseButton boxSize={10} fontSize={"md"} top={3} right={6} />

                <PopoverHeader border={0} px={0} py={0} mb={6}>
                    <Heading as={"h2"} variant={"h3"}>
                        New asset
                    </Heading>
                </PopoverHeader>

                <PopoverBody as={Stack} gap={6} px={0} py={0}>
                    {/*Upload drop area*/}
                    <UploadBox maxFiles={12} onDropAccepted={(files: File[]) => mergeFiles(files)} />

                    {/*Selected files list*/}
                    {form.watch("files").length > 0 && (
                        <Stack>
                            {form.watch("files").map((file: File, i) => (
                                <HStack
                                    borderWidth={1}
                                    borderRadius={"2xl"}
                                    borderColor={"gray.400"}
                                    key={i}
                                    px={4}
                                    py={2}
                                    gap={4}
                                >
                                    <Img src={FileIcon} h={12} />

                                    <Text variant={"title"} color={"black.800"} isTruncated={true}>
                                        {file.name}
                                    </Text>
                                    <Spacer />
                                    <IconButton
                                        variant={"ghost"}
                                        aria-label={"Remove file"}
                                        icon={<FontAwesomeIcon icon={faXmarkCircle} size={"xl"} />}
                                        onClick={() => removeFile(i)}
                                    />
                                </HStack>
                            ))}
                        </Stack>
                    )}

                    {/*Cancel & Upload buttons*/}
                    <HStack justify={"center"}>
                        <Button
                            bg={"gray.300"}
                            onClick={() => {
                                form.reset();
                                popoverDisclosure.onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            colorScheme={"purple"}
                            onClick={onSubmit}
                            isLoading={isUploading}
                            isDisabled={form.watch("files").length === 0}
                        >
                            Upload assets
                        </Button>
                    </HStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
