import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";
import { getErrorDetails } from "../util/getErrorMessage.ts";

export const useAddMember = (caseId: number, onSuccess?: () => void) => {
    const toast = useToast();

    return useMutation({
        mutationFn: async (email: string) => {
            await sdk.functional.$case.member.addMember(getApiConnection(), caseId, { email });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["members", caseId]);
            onSuccess && onSuccess();
            toast({
                title: "Member added",
                status: "success",
            });
        },
    });
};
