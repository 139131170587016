/**
 * @packageDocumentation
 * @module api.functional.healthz
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from '@nestia/fetcher';
import { PlainFetcher } from '@nestia/fetcher/lib/PlainFetcher';

/**
 * @controller AppController.health
 * @path GET /healthz
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function health(connection: IConnection): Promise<health.Output> {
    return PlainFetcher.fetch(connection, {
        ...health.METADATA,
        template: health.METADATA.path,
        path: health.path(),
    });
}
export namespace health {
    export type Output = {
        status: string;
    };

    export const METADATA = {
        method: "GET",
        path: "/healthz",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/healthz";
}
