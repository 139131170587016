import { Avatar, AvatarGroup, Button, HStack, Icon, IconButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useMembersQuery } from "../../../lib/queries/useMembersQuery.ts";
import { MembersModal } from "../membersModal/MembersModal.tsx";

interface ShareButtonProps {
    caseId: number;
    variant?: "compact" | "full";
}
export const ShareButton: FC<ShareButtonProps> = ({ caseId, variant = "full" }) => {
    // Members
    const { data: members } = useMembersQuery(caseId);

    const { isOpen, onOpen, onClose } = useDisclosure({});

    // Avatar group (with overflow counter)
    const avatars = (
        <Tooltip hasArrow label={members?.map((member) => member.user.name).join(", ")}>
            <AvatarGroup spacing={-2} max={3} size={"sm"}>
                {members?.map((member) => (
                    <Avatar key={member.user.id} name={member.user.name} src={member.user.picture || undefined} />
                ))}
            </AvatarGroup>
        </Tooltip>
    );

    return (
        <>
            {/* @todo ghost variant styling */}
            {variant === "full" && (
                <HStack gap={2}>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            onOpen();
                        }}
                        variant={"subtle"}
                        leftIcon={<Icon size={"lg"} as={FontAwesomeIcon} icon={faShareNodes} />}
                    >
                        Share case
                    </Button>
                    {members && members.length > 1 && avatars}
                </HStack>
            )}

            {variant === "compact" && (
                <HStack gap={3}>
                    {members && members.length > 1 && avatars}
                    <Tooltip hasArrow label={"Share case"}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onOpen();
                            }}
                            borderRadius={"lg"}
                            bg={"gray.300"}
                            size={"sm"}
                            aria-label={"Share case"}
                            icon={<Icon size={"lg"} as={FontAwesomeIcon} icon={faShareNodes} />}
                        />
                    </Tooltip>
                </HStack>
            )}
            <MembersModal caseId={caseId} isOpen={isOpen} onClose={onClose} />
        </>
    );
};
