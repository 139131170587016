import {
    Avatar,
    Box,
    Heading,
    HStack,
    Icon,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    Text,
} from "@chakra-ui/react";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useAuth } from "react-oidc-context";

interface Props {
    expanded: boolean;
}
export const UserPopover: FC<Props> = ({ expanded }) => {
    const { isAuthenticated, user } = useAuth();
    const { signoutRedirect } = useAuth();

    if (!isAuthenticated || !user) return null;

    return (
        <Menu variant={"userMenu"} placement={"end-end"} autoSelect={false}>
            <MenuButton>
                <HStack gap={2}>
                    <Avatar size={"sm"} boxSize={"40px"} name={user.profile.name} src={user.profile.picture} />
                    {expanded && (
                        <Stack w="0" flexGrow={1} align="flex-start" gap={0}>
                            <Text maxW="full" isTruncated={true} variant="labelBold" color="white" size="sm">
                                {user.profile.name}
                            </Text>
                            <Text maxW="full" isTruncated={true} variant="label" colorScheme="gray.500">
                                {user.profile.email}
                            </Text>
                        </Stack>
                    )}
                </HStack>
            </MenuButton>
            <MenuList display="flex" flexDirection="column" gap={2}>
                <HStack px={2} gap={3}>
                    <Avatar size={"lg"} name={user.profile.name} src={user.profile.picture} />
                    <Box>
                        <Heading color="white" size="sm">
                            {user.profile.name}
                        </Heading>
                        <Text color="white">{user.profile.email}</Text>
                    </Box>
                </HStack>
                <MenuDivider mx={3} color="gray.800" />
                <MenuItem
                    bg="black.500"
                    gap={1}
                    py={2}
                    icon={<Icon color={"gray.500"} size={"xl"} as={FontAwesomeIcon} icon={faArrowRightFromBracket} />}
                    onClick={() => signoutRedirect()}
                >
                    <Text color="white" variant={"body2"}>
                        Log out
                    </Text>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
