import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api";

export const useNotificationsQuery = (enabled?: boolean) => {
    return useQuery({
        queryKey: ["notifications"],
        queryFn: () => sdk.functional.notification.getNotifications(getApiConnection()),
        refetchInterval: 1000 * 60 * 1, // 1 minute
        enabled,
        refetchOnWindowFocus: true,
    });
};
