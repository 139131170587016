import {
    Avatar,
    Heading,
    HStack,
    IconButton,
    SkeletonText,
    Spacer,
    Spinner,
    Stack,
    Tag,
    TagLabel,
    Text,
} from "@chakra-ui/react";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faLink, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ShareButton } from "../../../components/members/shareButton/ShareButton.tsx";
import { useRegenerateCaseMutation } from "../../../lib/mutations/useRegenerateCaseMutation.ts";
import { useAttemptsQuery } from "../../../lib/queries/useAttemptsQuery.ts";
import { getCase } from "../../../sdk/functional/$case";

interface CaseHeaderProps {
    kees: getCase.Output;
}
export const CaseHeader: FC<CaseHeaderProps> = ({ kees }) => {
    const { mutate: regenerate } = useRegenerateCaseMutation(kees.id);
    const { data: indicators } = useAttemptsQuery(kees.id);

    return (
        <Stack gap={8}>
            <HStack alignItems={"start"}>
                {/*company Icon*/}
                <Avatar
                    name={kees.name}
                    size={"xl"}
                    boxSize={20}
                    borderRadius={"3xl"}
                    src={kees.logo_url ?? undefined}
                />
                <Spacer />

                {/* share case button */}
                <ShareButton variant={"full"} caseId={kees.id} />
            </HStack>

            {/*Title + description*/}
            <Stack gap={4}>
                <HStack>
                    <Heading variant={"h1"} as={"h1"}>
                        {kees.name}
                    </Heading>
                    <Spacer />
                    {indicators?.description && (
                        <Tag variant={"loader"}>
                            <Spinner color={"purple.500"} speed={"1s"} size="xs" />
                            <TagLabel>Generating case</TagLabel>
                        </Tag>
                    )}
                </HStack>

                {kees.description && <Text lineHeight={"taller"}>{kees.description}</Text>}
                {!kees.description && <SkeletonText noOfLines={4} skeletonHeight={3} spacing={3} w={"full"} my={4} />}
            </Stack>

            {/*Buttons*/}
            <HStack gap={4}>
                {kees.website && (
                    <Link to={kees.website} target={"_blank"} rel={"noreferrer"}>
                        <IconButton
                            borderRadius={"lg"}
                            aria-label={"Website"}
                            size={"sm"}
                            colorScheme={"gray"}
                            bg={"gray.300"}
                            icon={<FontAwesomeIcon icon={faLink} />}
                        />
                    </Link>
                )}

                {kees.linkedin_url && (
                    <Link to={kees.linkedin_url} target={"_blank"} rel={"noreferrer"}>
                        <IconButton
                            borderRadius={"lg"}
                            aria-label={"Linkedin"}
                            colorScheme={"linkedinBlue"}
                            size={"sm"}
                            icon={<FontAwesomeIcon icon={faLinkedinIn} />}
                        />
                    </Link>
                )}

                <Spacer />

                <IconButton
                    aria-label={"Regenerate"}
                    size={"sm"}
                    colorScheme={"gray"}
                    bg={"gray.300"}
                    borderRadius={"lg"}
                    icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
                    onClick={() => regenerate()}
                />
            </HStack>
            {/*</Center>*/}
            {/*</Card>*/}
        </Stack>
    );
};
