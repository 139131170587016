import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

// https://chakra-ui.com/docs/components/avatar/theming
const baseStyle = definePartsStyle({
    // define the part you're going to style
    container: {
        bg: "black.800",
        color: "white",
        fontWeight: "bold",
    },

    // extra counter for avatar group overflow. ex. (+2)
    excessLabel: {
        bg: "gray.400",
        color: "gray.600",
        fontWeight: "bold",
    },
});

export const avatarTheme = defineMultiStyleConfig({ baseStyle });
