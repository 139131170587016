import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '../../sdk';
import { getApiConnection } from '../app/api';

/**
 * Hook to mark a notification as read
 * Invalidates notifications query after successful mutation
 */
export const useMarkNotificationsRead = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            await api.functional.notification.markAllRead(getApiConnection());
        },
        onSuccess: () => {
            // Invalidate notifications query to refetch with updated read status
            queryClient.invalidateQueries({ queryKey: ["notifications"] });
        },
    });
};
