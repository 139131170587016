import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";
import { getErrorDetails } from "../util/getErrorMessage.ts";

export const useRemoveMember = (caseId: number) => {
    const toast = useToast();

    return useMutation({
        mutationFn: async (memberId: number) => {
            await sdk.functional.$case.member.removeMember(getApiConnection(), caseId, memberId);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["members", caseId]);
            toast({
                title: "Member removed",
                status: "success",
            });
        },
        onError: (e: unknown) => {
            const error = getErrorDetails(e);
            toast({
                title: "Failed to remove member",
                description: error.message,
                status: "error",
            });
        },
    });
};
