import { Box, Button, Heading, HStack, Icon, Spacer, Spinner, Stack } from "@chakra-ui/react";
import { faRotate } from "@fortawesome/free-solid-svg-icons/faRotate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { useMemoTopicsQuery } from "../../lib/queries/useMemoTopicsQuery.ts";
import { useScrollSpy } from "../../lib/util/useScrollSpy.ts";
import { MemoTopic } from "./components/MemoTopic.tsx";

interface MemoPageProps {}
export const MemoPage: FC<MemoPageProps> = ({}) => {
    const { caseId } = useParams<{ caseId: string }>();

    const { data: topics, isLoading: isTopicsLoading } = useMemoTopicsQuery();

    const memosRef = useRef<HTMLDivElement>(null);
    const { bindElement, activeSection, scrollToElement } = useScrollSpy();

    const [pendingTopics, setPendingTopics] = useState<string[]>([]);

    return (
        <SideNavLayout>
            <Stack gap={8}>
                {/*Title and regenerate button*/}
                <HStack gap={12}>
                    <Heading as={"h1"} variant={"h1"}>
                        Memo
                    </Heading>
                    <Spacer />
                    {/*If no memos, the empty state will have a button*/}
                    <Button
                        bg={"gray.300"}
                        leftIcon={<Icon as={FontAwesomeIcon} icon={faRotate} />}
                        isDisabled={pendingTopics.length > 0}
                        onClick={() => setPendingTopics(topics?.map((topic) => topic.key) ?? [])}
                    >
                        {pendingTopics.length === 0 && "Regenerate all topics"}
                        {pendingTopics.length > 0 && `Regenerating ${pendingTopics.length} topics`}
                    </Button>
                    {/*Match the side nav to offset the regenerate button*/}
                    <Stack w={"64"} flexShrink={0} />
                </HStack>

                {/* No memos yet empty state */}
                {/*{memos && memos?.length === 0 && (*/}
                {/*    <EmptyState minH={"60vh"} maxW={"400px"} imageSrc={memoImage}>*/}
                {/*        <Text py={3} fontSize={"lg"}>*/}
                {/*            This case has no memo yet*/}
                {/*        </Text>*/}
                {/*        <Button colorScheme={"purple"} isLoading={isRegenerating} onClick={() => regenerateMemo()}>*/}
                {/*            Generate memo*/}
                {/*        </Button>*/}
                {/*    </EmptyState>*/}
                {/*)}*/}

                {/* Spinner while loading topics*/}
                {isTopicsLoading && <Spinner />}

                {/*Memo cards and sidebar*/}
                <HStack gap={12} w={"full"} align={"start"}>
                    {/*Memo cards*/}
                    <Stack flexGrow={1} gap={8}>
                        {/* heading + regenerate button */}

                        {/* Memo cards */}
                        <Stack ref={memosRef} gap={8}>
                            {topics?.map((topic) => (
                                <Box ref={bindElement} key={topic.id} id={topic.key}>
                                    <MemoTopic
                                        topic={topic}
                                        caseId={parseInt(caseId ?? "")}
                                        shouldRegenerate={pendingTopics.includes(topic.key)}
                                        onRegenerateComplete={() =>
                                            // Remove topic from pending topics
                                            setPendingTopics((prev) => prev.filter((t) => t !== topic.key))
                                        }
                                    />
                                </Box>
                            ))}
                        </Stack>
                    </Stack>

                    {/*Table of Contents*/}
                    <Stack w={"64"} flexShrink={0} pos={"sticky"} top={"10"}>
                        {topics?.map((topic) => (
                            <Button
                                variant={"menuButton"}
                                isActive={activeSection === topic.key}
                                key={topic.id}
                                onClick={() => scrollToElement(topic.key)}
                            >
                                {topic.display_name}
                            </Button>
                        ))}
                    </Stack>
                </HStack>
            </Stack>
        </SideNavLayout>
    );
};
