import { BackgroundProps, Box, BoxProps } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface Props extends BoxProps {
    offsetTop?: number;
    offsetRight?: number;
    borderColor?: BackgroundProps["bg"];
    enabled?: boolean;
    lighten?: boolean;
    darken?: boolean;
    children: ReactNode;
}
export const NotificationBadge: FC<Props> = ({
    children,
    offsetTop = -1.5,
    offsetRight = -1.5,
    borderColor = "black.800",
    lighten,
    darken,
    enabled = true,
    ...props
}) => {
    return (
        <Box
            pos={"relative"}
            bg={"inherit"}
            //
            blendMode={lighten ? "lighten" : darken ? "darken" : undefined}
            {...props}
        >
            {children}
            {enabled && (
                <Box
                    rounded={"full"}
                    bg={"red.500"}
                    boxSize={4}
                    pos={"absolute"}
                    top={offsetTop}
                    right={offsetRight}
                    borderWidth={2}
                    borderColor={lighten ? "black" : darken ? "white" : borderColor}
                />
            )}
        </Box>
    );
};
