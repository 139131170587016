import {
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    Heading,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { queryClient } from "../../../lib/app/queryClient.ts";
import { useAddMember } from "../../../lib/mutations/useAddMember.ts";
import { useMembersQuery } from "../../../lib/queries/useMembersQuery.ts";
import { getErrorDetails } from "../../../lib/util/getErrorMessage.ts";
import { EmptyState } from "../../misc/emptyState/EmptyState.tsx";
import { MemberCard } from "../memberCard/MemberCard.tsx";
import { AnimatePresence, motion } from "framer-motion";

interface AddMemberForm {
    email: string;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    caseId: number;
}
export const MembersModal: FC<Props> = ({ isOpen = false, onClose, caseId }) => {
    // Queries
    const { data: members, isLoading } = useMembersQuery(caseId);

    // Mutations
    const {
        mutate: addMember,
        isLoading: isAddMemberLoading,
        error: addMemberError,
        reset: resetAddMember,
    } = useAddMember(caseId, () => reset());

    const addMemberErrorDetails = getErrorDetails(addMemberError).message;

    //Form
    const { register, reset, handleSubmit, formState } = useForm<AddMemberForm>({});

    const handleClose = () => {
        onClose();
        reset();
        resetAddMember();
    };

    return (
        <Modal isCentered={true} isOpen={isOpen} onClose={handleClose} size={"2xl"}>
            {/* Overlay */}
            <ModalOverlay />

            <ModalContent
                rounded={"3xl"}
                as={motion.div}
                initial={{ opacity: 1, x: 0 }}
                animate={{
                    opacity: 1,
                    x: addMemberError ? [0, -10, 10, -10, 10, 0] : 0,
                    transition: { duration: 0.5, ease: "easeInOut" },
                }}
            >
                <ModalHeader>
                    {/* title/description */}
                    <Heading variant={"h3"}>Manage Members</Heading>
                    <Text pt={2} fontWeight={"normal"} variant={"body2"}>
                        Invite people to collaborate on the case with you. They will have access to the shared company
                        page and assets.
                    </Text>
                </ModalHeader>

                <ModalCloseButton size={"lg"} />

                <ModalBody pb={8}>
                    <Stack gap={8}>
                        <HStack align={"start"} as={"form"} onSubmit={handleSubmit((data) => addMember(data.email))}>
                            {/* email input */}
                            <FormControl isInvalid={!!addMemberError}>
                                <Input
                                    {...register("email", {
                                        required: true,
                                        // Check that its a valid email
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Please enter a valid email",
                                        },
                                    })}
                                    data-lpignore="true"
                                    autoComplete="off"
                                    type="email"
                                    placeholder="Invite by email"
                                    variant="gray"
                                />
                                {!addMemberError && (
                                    <FormHelperText>
                                        Note: you can only add members that have logged in at least once
                                    </FormHelperText>
                                )}
                                {!!addMemberError && (
                                    // Fade animation for error text
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{
                                            opacity: 1,
                                            transition: { duration: 0.5, ease: "easeIn" },
                                        }}
                                    >
                                        {/* input error text */}
                                        <FormErrorMessage color={"error.500"}>{addMemberErrorDetails}</FormErrorMessage>
                                    </motion.div>
                                )}
                            </FormControl>
                            {/* invite button */}
                            <Button
                                colorScheme={"purple"}
                                type={"submit"}
                                isLoading={isAddMemberLoading}
                                isDisabled={!formState.isValid}
                                fontWeight={"semibold"}
                            >
                                Invite
                            </Button>
                        </HStack>

                        <Stack>
                            {/* Member count */}
                            {members && members.length && <Text variant={"title"}>{members.length} Members</Text>}

                            {/* Members list */}
                            {members?.map((member) => <MemberCard member={member} caseId={caseId} key={member.id} />)}
                        </Stack>

                        {/*Empty state*/}
                        {!isLoading && !members?.length && (
                            <EmptyState imageHidden>
                                <Text>No members have been added to this case yet.</Text>
                            </EmptyState>
                        )}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
