import { Button, Icon, Menu, MenuButton, useDisclosure } from "@chakra-ui/react";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { useNotificationsQuery } from "../../../../lib/queries/useNotificationsQuery.ts";
import { NotificationBadge } from "../../../misc/notificationBadge/NotificationBadge.tsx";
import { Notifications } from "../../../notifications/Notifications.tsx";

interface Props {
    expanded: boolean;
}
export const NotificationsPopover: FC<Props> = ({ expanded }) => {
    const { isAuthenticated, user } = useAuth();

    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data: notifications } = useNotificationsQuery();
    const hasNewNotifications = !!notifications?.some((n) => !n.is_read);

    if (!isAuthenticated || !user) return null;

    return (
        <Menu
            variant={"userMenu"}
            placement={"end-end"}
            autoSelect={false}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
        >
            <MenuButton
                as={Button}
                variant={"menuButton"}
                iconSpacing={4}
                leftIcon={
                    <NotificationBadge lighten={true} enabled={hasNewNotifications}>
                        <Icon size={"xl"} as={FontAwesomeIcon} icon={faBell} />
                    </NotificationBadge>
                }
                textAlign={"left"}
            >
                {expanded && "Notifications"}
            </MenuButton>

            <Notifications isVisible={isOpen} />
        </Menu>
    );
};
