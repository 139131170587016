import {
    Avatar,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Center,
    Heading,
    HStack,
    IconButton,
    SkeletonCircle,
    SkeletonText,
    Spacer,
    Spinner,
    Stack,
    Tag,
    TagLabel,
    Text,
} from "@chakra-ui/react";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useAttemptsQuery } from "../../../lib/queries/useAttemptsQuery.ts";
import { useEmployeesQuery } from "../../../lib/queries/useEmployeesQuery.ts";

interface EmployeesProps {
    caseId: number;
}

export const Employees: FC<EmployeesProps> = ({ caseId }) => {
    const { data: employees, isLoading: isEmployeesLoading } = useEmployeesQuery(caseId);
    const { data: indicators } = useAttemptsQuery(caseId);
    const [showAll, setShowAll] = useState(false);

    return (
        <Stack gap={6}>
            <HStack>
                <Heading as={"h2"} variant={"h2"}>
                    Team
                </Heading>
                <Spacer />
                {indicators?.employees && (
                    <Tag variant={"loader"}>
                        <Spinner color={"purple.500"} speed={"1s"} size="xs" />
                        <TagLabel>Finding team members</TagLabel>
                    </Tag>
                )}
            </HStack>

            {/*Empty state*/}
            {/*Not loading, not pending, no employees found*/}
            {!isEmployeesLoading && !indicators?.employees && employees?.length === 0 && (
                <Center bg={"gray.100"} p={10} borderRadius={"3xl"} color={"gray.400"}>
                    <Text>No team members found</Text>
                </Center>
            )}

            {/* Skeleton loader */}
            {/*Loading, or pending with no employees found *!/*/}
            {(isEmployeesLoading || (indicators?.employees && !employees?.length)) && (
                <Stack gap={4}>
                    {Array.from({ length: 3 }).map((_, i) => (
                        <Card bg={"gray.100"} shadow={"none"} borderRadius={"3xl"} p={4} pr={5} key={i}>
                            <CardBody>
                                <HStack gap={4}>
                                    <SkeletonCircle size={"12"} />
                                    <SkeletonText noOfLines={2} spacing={4} w={"30%"} />
                                    <Spacer />
                                    {/* Button skeleton  */}
                                    <SkeletonCircle borderRadius={"lg"} size={"8"} />
                                </HStack>
                            </CardBody>
                        </Card>
                    ))}
                </Stack>
            )}

            {/*Employees found*/}
            {employees && employees?.length > 0 && (
                <Stack gap={4}>
                    {employees.slice(0, showAll ? undefined : 3).map((employee) => (
                        <Card key={employee.id} p={4} pr={5}>
                            <CardBody p={0}>
                                <HStack gap={4}>
                                    <Avatar size={"md"} src={employee.image_url ?? undefined} name={employee.name} />
                                    <Stack gap={0}>
                                        <Text variant={"title"}>{employee.name}</Text>
                                        <Text variant={"body2"}>{employee.title}</Text>
                                    </Stack>
                                    <Spacer />
                                    <ButtonGroup>
                                        {employee.linkedin_url && (
                                            <Link to={employee.linkedin_url} target={"_blank"} rel={"noreferrer"}>
                                                <IconButton
                                                    borderRadius={"lg"}
                                                    aria-label={"Linkedin"}
                                                    variant={"solid"}
                                                    colorScheme={"linkedinBlue"}
                                                    size={"sm"}
                                                    icon={<FontAwesomeIcon icon={faLinkedinIn} />}
                                                />
                                            </Link>
                                        )}
                                    </ButtonGroup>
                                </HStack>
                            </CardBody>
                        </Card>
                    ))}
                </Stack>
            )}

            {employees && employees.length > 3 && (
                <Center>
                    <Button
                        onClick={() => setShowAll(!showAll)}
                        bg={"gray.300"}
                        rightIcon={<FontAwesomeIcon icon={showAll ? faChevronUp : faChevronDown} size={"xs"} />}
                    >
                        {showAll ? "Show less" : `Show ${employees.length - 3} more`}
                    </Button>
                </Center>
            )}
        </Stack>
    );
};
