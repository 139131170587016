import {
    Avatar,
    Center,
    HStack,
    Icon,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList,
    Spacer,
    Spinner,
    Text,
} from "@chakra-ui/react";
import { faFolder } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { useCaseQuery } from "../../../../lib/queries/useCaseQuery.ts";
import { useCasesQuery } from "../../../../lib/queries/useCasesQuery.ts";

interface CaseDropdownProps {}
export const CaseDropdown: FC<CaseDropdownProps> = ({}) => {
    const { data: cases } = useCasesQuery();

    const { caseId: caseIdStr } = useParams();
    const caseId = parseInt(caseIdStr || "");

    const { data: kees } = useCaseQuery(caseId);

    return (
        <Menu variant={"casePicker"}>
            <MenuButton>
                <HStack>
                    {!kees && (
                        <Center boxSize={"40px"}>
                            <Spinner size={"sm"} color={"white"} />
                        </Center>
                    )}
                    {kees && (
                        <>
                            <Avatar
                                borderRadius="xl"
                                name={kees?.name}
                                size={"sm"}
                                boxSize={"40px"}
                                src={kees?.logo_url ?? undefined}
                            />
                            <Text variant={"menuActive"} color={"black.800"} isTruncated>
                                {kees?.name}
                            </Text>
                        </>
                    )}
                    <Spacer />
                    <Icon as={FontAwesomeIcon} icon={faChevronDown} />
                </HStack>
            </MenuButton>
            <MenuList>
                <MenuGroup title="Recent">
                    {cases?.slice(0, 3)?.map((c) => (
                        <MenuItem
                            key={c.id}
                            as={Link}
                            to={`/cases/${c.id}`}
                            bg={c.id === caseId ? "purple.300" : undefined}
                            color={c.id === caseId ? "black.500" : undefined}
                            _hover={c.id === caseId ? { bg: "purple.200" } : undefined}
                        >
                            <Avatar borderRadius="lg" name={c.name} size={"sm"} src={c.logo_url ?? undefined} />
                            <span>{c.name}</span>
                        </MenuItem>
                    ))}
                </MenuGroup>
                <MenuDivider />
                <MenuItem as={Link} to={"/cases"} fontWeight={"normal"}>
                    <Center w={8}>
                        <Icon as={FontAwesomeIcon} icon={faFolder} size={"lg"} />
                    </Center>
                    <span>All cases</span>
                </MenuItem>
                <MenuItem as={Link} to={"/cases/new"} fontWeight={"normal"}>
                    <Center w={8}>
                        <Icon as={FontAwesomeIcon} icon={faPlus} size={"lg"} />
                    </Center>
                    <span>Create new case</span>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
