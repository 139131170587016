/**
 * @packageDocumentation
 * @module api.functional.memo.topic
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from '@nestia/fetcher';
import { PlainFetcher } from '@nestia/fetcher/lib/PlainFetcher';

import type { TopicInfo } from '../../../structures/TopicInfo';

/**
 * @controller MemoController.getTopics
 * @path GET /memo/topic
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getTopics(connection: IConnection): Promise<getTopics.Output> {
    return PlainFetcher.fetch(connection, {
        ...getTopics.METADATA,
        template: getTopics.METADATA.path,
        path: getTopics.path(),
    });
}
export namespace getTopics {
    export type Output = TopicInfo[];

    export const METADATA = {
        method: "GET",
        path: "/memo/topic",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/memo/topic";
}
