/**
 * @packageDocumentation
 * @module api.functional.memo.cases.topic.regenerate
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from '@nestia/fetcher';
import { PlainFetcher } from '@nestia/fetcher/lib/PlainFetcher';

/**
 * @controller MemoController.regenerateMemo
 * @path POST /memo/cases/:caseId/topic/:topic/regenerate
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function regenerateMemo(
    connection: IConnection,
    caseId: number,
    topic: string,
): Promise<regenerateMemo.Output> {
    return PlainFetcher.fetch(connection, {
        ...regenerateMemo.METADATA,
        template: regenerateMemo.METADATA.path,
        path: regenerateMemo.path(caseId, topic),
    });
}
export namespace regenerateMemo {
    export type Output = any;

    export const METADATA = {
        method: "POST",
        path: "/memo/cases/:caseId/topic/:topic/regenerate",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, topic: string) =>
        `/memo/cases/${encodeURIComponent(caseId ?? "null")}/topic/${encodeURIComponent(topic ?? "null")}/regenerate`;
}
