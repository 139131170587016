import { Box, BoxProps, Center, ChakraProps, Image, Stack, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import AssetsPlaceholder from "../../../assets/images/assets-placeholder.svg";

interface EmptyStateProps extends BoxProps {
    /**
     * If null, no image will be displayed
     * When nothing is passed, a default image will be displayed
     */
    imageSrc?: string;
    children?: ReactNode | undefined;
    imageHeight?: ChakraProps["maxH"];
    imageHidden?: boolean;
    maxW?: ChakraProps["maxW"];
    minH?: ChakraProps["minH"];
    py?: ChakraProps["py"];
    px?: ChakraProps["px"];
}
export const EmptyState: FC<EmptyStateProps> = ({
    imageSrc,
    imageHeight,
    maxW,
    minH,
    py,
    px,
    children,
    imageHidden,
}) => {
    return (
        <Center py={py} px={px} minH={minH}>
            <Box maxW={maxW || "300px"}>
                <Stack gap={"8"}>
                    {!imageHidden && (
                        <Image alt="empty image" src={imageSrc || AssetsPlaceholder} maxH={imageHeight || "200px"} />
                    )}
                    <Box textAlign="center" color="gray.600">
                        {children}
                    </Box>
                </Stack>
            </Box>
        </Center>
    );
};
