import { Button, Heading, HStack, MenuItem, MenuList, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { useClearNotifications } from "../../lib/mutations/useClearNotifications.ts";
import { useMarkNotificationsRead } from "../../lib/mutations/useMarkNotificationsRead.ts";
import { useNotificationsQuery } from "../../lib/queries/useNotificationsQuery";
import { Notification } from "./Notification.tsx";

interface NotificationsProps {
    isVisible: boolean;
}
export const Notifications: FC<NotificationsProps> = ({ isVisible }) => {
    const { data: notifications, isLoading } = useNotificationsQuery(isVisible);

    const { mutate: markNotificationsRead } = useMarkNotificationsRead();
    const { mutate: clearNotifications, isLoading: isClearingNotifications } = useClearNotifications();

    const hasNewNotifications = notifications?.some((n) => !n.is_read);

    const lastIsVisible = usePrevious(isVisible);

    useEffect(() => {
        if (!isVisible && lastIsVisible && hasNewNotifications) {
            markNotificationsRead();
            console.log("Marking notifications as read");
        }
    }, [isVisible, lastIsVisible, markNotificationsRead]);

    return (
        <MenuList
            onClick={() => {
                markNotificationsRead();
            }}
        >
            <Stack>
                {/*Title and clear button*/}
                <HStack align={"start"}>
                    <Heading color="white" size="md">
                        Notifications
                    </Heading>
                    <Spacer />
                    <Button
                        size="sm"
                        variant="link"
                        color="gray.500"
                        onClick={() => clearNotifications()}
                        disabled={isClearingNotifications}
                    >
                        Clear all
                    </Button>
                </HStack>
                <Spacer />

                {(isLoading || isClearingNotifications) && <Spinner />}

                {!isLoading && (!notifications || notifications.length === 0) && (
                    <MenuItem bg="black.500" gap={1} py={2}>
                        <Text color="gray.500" variant={"body2"}>
                            No new notifications
                        </Text>
                    </MenuItem>
                )}

                {!isLoading &&
                    notifications &&
                    notifications.length > 0 &&
                    notifications.map((notification) => (
                        <Notification key={notification.id} notification={notification} />
                    ))}
            </Stack>
        </MenuList>
    );
};
