import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useCurrentUserQuery = () => {
    return useQuery({
        queryKey: ["currentUser"],
        queryFn: async () => {
            return await sdk.functional.user.me.getCurrentUser(getApiConnection());
        },
        refetchOnWindowFocus: false,
    });
};
