/**
 * @packageDocumentation
 * @module api.functional
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from '@nestia/fetcher';
import { PlainFetcher } from '@nestia/fetcher/lib/PlainFetcher';

export * as healthz from "./healthz";
export * as $case from "./$case";
export * as user from "./user";
export * as notification from "./notification";
export * as asset from "./asset";
export * as bot from "./bot";
export * as ai from "./ai";
export * as memo from "./memo";

/**
 * Hello!
 * @controller AppController.version
 * @path GET /
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function version(connection: IConnection): Promise<version.Output> {
    return PlainFetcher.fetch(connection, {
        ...version.METADATA,
        template: version.METADATA.path,
        path: version.path(),
    });
}
export namespace version {
    export type Output = {
        version: string;
    };

    export const METADATA = {
        method: "GET",
        path: "/",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/";
}
