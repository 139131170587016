import {
    Button,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Skeleton,
    Spacer,
    Stack,
    Text,
} from "@chakra-ui/react";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTitle } from "react-use";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { useCasesQuery } from "../../lib/queries/useCasesQuery.ts";
import { getCases } from "../../sdk/functional/$case";
import { CaseItem } from "./components/CaseItem.tsx";

interface HomeProps {}
export const CasesPage: FC<HomeProps> = () => {
    useTitle("Cases - Maven");

    const { data: cases, isLoading } = useCasesQuery();

    const [query, setQuery] = useState("");

    const fuse = useMemo(
        () =>
            new Fuse(cases ?? ([] as getCases.Output), {
                keys: ["name", "description", "website"],
            }),
        [cases],
    );

    const filteredCases = query.length > 0 ? fuse.search(query).map((r) => r.item) : cases;

    return (
        <SideNavLayout>
            <Stack gap={10}>
                {/*title, search, new case*/}
                <HStack gap={4}>
                    <Heading as={"h1"}>My cases</Heading>
                    <Spacer />

                    <InputGroup maxW={80} variant={"ghost"}>
                        <InputLeftElement pointerEvents="none">
                            <Icon icon={faMagnifyingGlass} as={FontAwesomeIcon} />
                        </InputLeftElement>
                        <Input placeholder={"Search cases"} onChange={(e) => setQuery(e.target.value)} />
                    </InputGroup>

                    <Link to={"/cases/new"}>
                        <Button colorScheme={"purple"} leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} />}>
                            New case
                        </Button>
                    </Link>
                </HStack>

                {/*loader*/}
                {isLoading && (
                    <Stack gap={4}>
                        <Skeleton height={120} borderRadius={"3xl"} />
                        <Skeleton height={120} borderRadius={"3xl"} />
                        <Skeleton height={120} borderRadius={"3xl"} />
                    </Stack>
                )}

                {filteredCases?.length === 0 && <Text>No cases found</Text>}
                {filteredCases && (
                    <Stack gap={6}>
                        {filteredCases.map((c) => (
                            <CaseItem kees={c} key={c.id} />
                        ))}
                    </Stack>
                )}
            </Stack>
        </SideNavLayout>
    );
};
