import { Avatar, Card, CardBody, Heading, HStack, IconButton, Spacer, Stack, Text } from "@chakra-ui/react";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ShareButton } from "../../../components/members/shareButton/ShareButton.tsx";
import { getCase } from "../../../sdk/functional/$case";

interface CaseItemProps {
    kees: getCase.Output;
}
export const CaseItem: FC<CaseItemProps> = ({ kees }) => {
    const navigate = useNavigate();

    return (
        <Card onClick={() => navigate("/cases/" + kees.id)} cursor={"pointer"}>
            <CardBody>
                <HStack justifyContent={"space-between"} gap={6} alignItems={"start"}>
                    <Avatar name={kees.name} size={"lg"} borderRadius={"xl"} src={kees.logo_url ?? undefined} />
                    <Stack gap={4} flexGrow={1} minH={16} justifyContent={"center"}>
                        <Link to={`/cases/${kees.id}`}>
                            {/* company case name */}
                            <Heading variant={"h2"}>{kees.name}</Heading>
                        </Link>

                        <Text variant={"body2"} color={"gray.600"}>
                            {kees.description ?? "No description"}
                        </Text>

                        <HStack onClick={(e) => e.stopPropagation()} alignSelf={"start"}>
                            {kees.website && (
                                <Link to={kees.website} target={"_blank"} rel={"noreferrer"}>
                                    <IconButton
                                        borderRadius={"lg"}
                                        aria-label={"Website"}
                                        variant={"solid"}
                                        colorScheme={"gray"}
                                        bg={"gray.300"}
                                        size={"sm"}
                                        icon={<FontAwesomeIcon icon={faLink} />}
                                    />
                                </Link>
                            )}

                            {kees.linkedin_url && (
                                <Link to={kees.linkedin_url} target={"_blank"} rel={"noreferrer"}>
                                    <IconButton
                                        borderRadius={"lg"}
                                        aria-label={"Linkedin"}
                                        variant={"solid"}
                                        colorScheme={"linkedinBlue"}
                                        size={"sm"}
                                        icon={<FontAwesomeIcon icon={faLinkedinIn} />}
                                    />
                                </Link>
                            )}
                        </HStack>
                    </Stack>

                    {/*Menu, updated*/}
                    <Stack alignSelf={"stretch"} align={"end"}>
                        {/*<IconButton*/}
                        {/*    aria-label={"More options"}*/}
                        {/*    icon={<FontAwesomeIcon size={"lg"} icon={faEllipsisVertical} />}*/}
                        {/*    colorScheme={"gray"}*/}
                        {/*    bg={"gray.300"}*/}
                        {/*    borderRadius={"xl"}*/}
                        {/*    onClick={(e) => {*/}
                        {/*        e.stopPropagation();*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <ShareButton variant={"compact"} caseId={kees.id} />
                        <Spacer />
                        <Text variant={"labelCaps"} color={"gray.500"} whiteSpace={"nowrap"}>
                            Created {DateTime.fromISO(kees.created_at).toRelative({ unit: "days" })}
                        </Text>
                    </Stack>
                </HStack>
            </CardBody>
        </Card>
    );
};
