import { Spacer, Stack } from "@chakra-ui/react";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useTitle } from "react-use";
import { Employees } from "../../components/case/employees/Employees.tsx";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { SimilarCompanies } from "../../components/similarCompanies/similarCompanies/SimilarCompanies.tsx";
import { useCaseQuery } from "../../lib/queries/useCaseQuery.ts";
import { useErrorToast } from "../../lib/util/useErrorToast.ts";
import { CaseHeader } from "./components/CaseHeader.tsx";
import { useCaseSSE } from "./hooks/useCaseSSE.ts";

interface HomeProps {}
export const CasePage: FC<HomeProps> = () => {
    const { caseId: caseIdStr } = useParams<{ caseId: string }>();
    const caseId = parseInt(caseIdStr ?? "-1");
    const { data: kees, error } = useCaseQuery(caseId);

    useCaseSSE(caseId);
    useTitle(`${kees?.name ?? "Case"} - Maven`);

    useErrorToast(error);

    return (
        <SideNavLayout>
            {kees && (
                <Stack gap={20}>
                    <CaseHeader kees={kees} />
                    <Employees caseId={caseId} />
                    <SimilarCompanies caseId={caseId} />
                    <Spacer />
                </Stack>
            )}
        </SideNavLayout>
    );
};
